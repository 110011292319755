// Misc SCSS variables

$black: #000000;
$darker-gray: #111111;
$dark-gray: #222222;
$med-dark-gray: #373737;
$med-gray: #2c3546;
$gray: #999999;
$light-gray: #f0f0f0;
$white: #ffffff;

$break-tiny: "576px";
$break-small: "768px";
$break-med: "992px";
$break-large: "1200px";

$break-mobile-height-landscape: "575.98px";

$alt-blue: #5a7b9d;
$blue: #335180;
$blue-highlight: #4779b2;
$link-blue: #2d91f6;

$success: #179b56;
$error: #b21d36;

$bgGradient1A: #1d597a;
$bgGradient1B: #263260;
$bgGradient1C: #19213f;


$bgGradient2A: #092031;
$bgGradient2B: #091525;
